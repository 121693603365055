.home-title {
  margin-top: 20px;
  padding-top: 10px;
}

.home-title-text {
  line-height: 70px;
}

.home-figure {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 10px 1%;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.home-figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.home-figure figcaption {
  padding: 2em;
  color: #fff;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.home-figure figcaption::before,
.home-figure figcaption::after {
  pointer-events: none;
}

.home-figure figcaption,
.home-figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.home-figure h2 {
  word-spacing: -0.15em;
  font-size: 1.25rem;
  font-weight: 300;

  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

.home-figure h2 span {
  font-weight: 800;
}

.home-figure h2,
.home-figure p {
  margin: 0;

  -webkit-transform: translate3d(0,40px,0);
  transform: translate3d(0,40px,0);
}

.home-figure p {
  letter-spacing: 1px;
  font-size: 68.5%;

  color: rgba(255,255,255,0.8);
  opacity: 0;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
  transition: opacity 0.2s, transform 0.35s;
}

.home-figure img {
  max-width: none;
  width: -webkit-calc(100% + 50px);
  width: calc(100% + 50px);
  opacity: 0.7;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-40px,0, 0);
  transform: translate3d(-40px,0,0);
}

.home-figure figcaption {
  text-align: left;
}

.home-figure figcaption > div {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  height: 50%;
}

.home-figure:hover img,
.home-figure:hover p {
  opacity: 1;
}

.home-figure:hover img,
.home-figure:hover h2,
.home-figure:hover p {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.home-figure:hover p {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
}