
@media screen and (max-width: 767px) {
  span.bs-stepper-label {
    font-size: 0.7rem;
  }
  
  span.bs-stepper-circle {
    font-size: 0.7rem;
  }
}

.bs-stepper-content {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .bs-stepper-content {
    font-size: 10px;
  }
}

.grey-panel {
  font-weight: 700;
  margin: 10px 0;
  padding: 10px 0;
  background-color: #E1E1E1;
}

div.grey-panel-row {
  width: 100%;
}

.date-label {
  text-align: right;
  margin-top: 3px;
  margin-bottom: 3px;
}

@media screen and (max-width: 767px) {
  .date-label {
    text-align: center;
  }
}

.date-box {
  text-align: left;
}

@media screen and (max-width: 767px) {
  .date-box {
    text-align: center;
  }
}

.react-datepicker-wrapper > .react-datepicker__input-container > input {
  text-align: center;
  width: 90%;
}

.validation > .react-datepicker-wrapper > .react-datepicker__input-container > input {
  border-color: #FF0000;
}

.form-body {
  padding: 0 20px;
}

.quantity {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .quantity {
    font-size: 10px;
    padding: 0.2rem 0.2rem;
    min-width: 50px;
  }
}

select.validation {
  border-color: #FF0000;
}

span.block {
  display: block;
}

.row-space-2 {
  margin: 2px 0;
}

.row-space-20 {
  margin: 20px 0;
}

.text-label {
  text-align: right;
}

@media screen and (max-width: 991px) {
  .text-label {
    text-align: left;
    margin-bottom: 5px;
  }
}

.react-bootstrap-table > table > caption {
  font-size: 18px;
  text-align: center;
}

.center-button {
  display: flex;
  justify-content: center;
}

.form-label {
  font-weight: 700;
  font-size: 14px;
}

.react-tel-input > input.form-control {
  width: 100%;
}

.error-text {
  color: #FF0000;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .mobile-size {
    font-size: 10px !important;
  }
}

.dob-box > .react-datepicker-wrapper {
  width: 100%;
}

.dob {
  text-align: left !important;
  width: 100% !important;
}

.table-button {
  padding: 0 10px;
}

.table-button:hover {
  cursor: pointer;
}

.table-button:active {
  cursor: pointer;
}

.price-discount {
  font-size: 12px;
  text-decoration: line-through;
}