.change-date-header {
  font-size: 14px;
  font-weight: 700;

  padding: 10px 0;

  color: #FFFFFF;
  background-color: #000000;
}

.change-date-panel {
  padding: 0 20px;
}

@media screen and (min-width: 1200px) {
  .change-date-panel {
    margin-bottom: 15px;
  }
}

.visit-date-box > .react-datepicker-wrapper {
  width: 100%;
}

.visit-date {
  text-align: left !important;
  width: 100% !important;
}

.change-date-search {
  text-align: left;
}

@media screen and (max-width: 1200px) {
  .change-date-search {
    padding-top: 15px;
    padding-bottom: 10px;
    text-align: center;
  }
}

.change-date-status {
  color: #FF0000;
}