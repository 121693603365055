.App {
  text-align: center;
}

@media screen and (min-width: 992px) {
  .mobile-tablet {
    display: none;
  }
}

@media screen and (max-width: 991px) {
 .laptop-desktop {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .mobile-hide {
    display: none;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: 700;
}