.layout {
  padding: 30px 15px;
}

@media screen and (max-width: 991px) {
  .layout {
    padding: 10px 15px;
  }
}

.space-pd-0 {
  padding: 0;
}

.space-pt-7 {
  padding-top: 7px;
}

.space-pt-10 {
  padding-top: 10px;
}

.space-pt-20 {
  padding-top: 20px;
}

.space-pb-30 {
  padding-bottom: 30px;
}

.space-ptb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.space-ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.space-mt-4 {
  margin-top: 4px;
}

.space-mt-10 {
  margin-top: 10px;
}

.space-mt-20 {
  margin-top: 20px;
}

.space-mb-5 {
  margin-bottom: 5px;
}

.space-mb-15 {
  margin-bottom: 15px;
}

.space-mb-30 {
  margin-bottom: 30px;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.carousel-inner {
  width:100%;
}