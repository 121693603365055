.page-wrap-4 {
  min-height: 75vh;
}

.header-4 {
  font-size: 96px;
  margin-top: 50px;
}

.text-4 {
  font-size: 20px;
  margin-bottom: 50px;
}

.link-4 {
  padding-right: 50px;
}