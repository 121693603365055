.payment-form {
  margin-top: 50px;
}

.panel {
  border: 1px solid #000000;
  margin-bottom: 20px;
}

.panel-header {
  padding: 10px 15px;
  background-color: #000000;
}

.panel-title {
  font-weight: 700;
  color: #FFFFFF;
}

.panel-body {
  padding: 15px;
}

.payment-option-box {
  font-weight: 700;
  border: 1px solid #CED4DA;
  border-radius: 5px;
  padding: 19px;
}

.payment-option-label {
  padding-left: 10px;
  vertical-align: 10%;
}

.payment-option-img {
  width: 70%;
}
