.renew-member-header {
  font-size: 14px;
  font-weight: 700;

  padding: 10px 0;

  color: #FFFFFF;
  background-color: #000000;
}

.renew-member-panel {
  padding: 0 20px;
}

@media screen and (min-width: 1200px) {
  .renew-member-panel {
    margin-bottom: 15px;
  }
}

.renew-date-box>.react-datepicker-wrapper {
  width: 100%;
}

.renew-date {
  text-align: left !important;
  width: 100% !important;
}

.renew-member-search {
  text-align: left;
}

@media screen and (max-width: 1200px) {
  .renew-member-search {
    padding-top: 15px;
    padding-bottom: 10px;
    text-align: center;
  }
}

.renew-member-status {
  color: #FF0000;
}

.form-group.floating {
  margin-bottom: 0;
}

.form-group.floating>label {
  bottom: 34px;
  left: 8px;
  position: relative;
  background-color: white;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.1s;
  pointer-events: none;
  font-weight: 500 !important;
  transform-origin: bottom left;
}

.form-control.floating:focus~label {
  transform: translate(1px, -85%) scale(0.80);
  opacity: .8;
  color: #005ebf;
}

.form-control.floating:valid~label {
  transform-origin: bottom left;
  transform: translate(1px, -85%) scale(0.80);
  opacity: .8;
}