.pass-types {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  height: 200px;
}

.pass-description {
  font-size: 22px;
  text-align: left;
}

.pass-read-more {
  cursor: pointer;
  color: #409AD5;
}

.pass-read-more:hover {
  color: #56BDDE;
}