.online-ticket-box {
  max-width: 100%;
}

.online-ticket-icon {
  padding-top: 50px;
}

@media screen and (max-width: 1200px) {
  .online-ticket-icon {
    padding-top: 0px;
  }
}

.online-ticket-image {
  width: 80%;
}