.footer-top {
  font-size: 12px;
  text-align: left;

  padding: 50px 0 30px;

  border-bottom: 1px solid #808080;

  background-color: #000000;
  color: #FFFFFF;

}

.footer-wrap {
  padding-left: 30px;
}

.footer-title {
  font-weight: 600;
}

.footer-icon {
  font-size: 16px;
  padding-right: 10px;
}

p.contact > a,
p.link > a,
p.link > span.footer-icon > a {
  color: #FFFFFF;
}

p.contact > a:hover,
p.link > a:hover,
p.link > span.footer-icon > a:hover {
  color: #808080;
  text-decoration: none;
}

span.block {
  display: block;
}

.footer-bottom {
  font-size: 12px;
  text-align: left;

  padding: 30px 0;

  background-color: #000000;
  color: #FFFFFF;

}

@media screen and (min-width: 768px) {
  .copyright {
    text-align: right;
  }
}

.footer-text {
  font-size: 14px;
  color: #FFFFFF;
}